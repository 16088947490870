import { Node as TiptapNode } from '@tiptap/core';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import BulletList from '@tiptap/extension-bullet-list';
import { Color } from '@tiptap/extension-color';
import Focus from '@tiptap/extension-focus';
import FontFamily from '@tiptap/extension-font-family';
import HardBreak from '@tiptap/extension-hard-break';
import { Heading } from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Link from '@tiptap/extension-link';
import OrderedList from '@tiptap/extension-ordered-list';
import Placeholder from '@tiptap/extension-placeholder';
import Strike from '@tiptap/extension-strike';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import { TextAlign } from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { type TFunction, useTranslation } from '@tyro/i18n';

import {
  Column,
  FourColumnLayout,
  ThreeColumnLayout,
  TwoColumnLayout,
} from '../components/text-editor/extensions/add-column-extension';
import { CustomNode } from '../components/text-editor/extensions/variable-chip';

interface ExtensionOptions {
  placeholder?: string;
  t: TFunction<'common'[]>;
}

const getExtensions = ({ placeholder, t }: ExtensionOptions) => [
  StarterKit.configure({
    heading: false,
  }),
  BubbleMenu,
  BulletList.configure({
    itemTypeName: 'listItem',
    keepMarks: false,
    keepAttributes: true,
  }),
  Color,
  Column,
  CustomNode,
  Focus.configure({
    className: 'has-focus',
    mode: 'deepest',
  }),
  FontFamily.configure({
    types: ['textStyle'],
  }),
  HardBreak.configure({
    keepMarks: true,
  }),
  Heading.configure({
    levels: [1, 2, 3, 4, 5, 6],
  }),
  Highlight.configure({
    multicolor: true,
  }),
  HorizontalRule.configure({
    HTMLAttributes: {
      class: 'tyro-hr',
    },
  }),
  Link.configure({
    openOnClick: false,
    autolink: false,
    validate: (href) => /^https?:\/\//.test(href),
  }),
  OrderedList.configure({
    itemTypeName: 'listItem',
  }),
  Placeholder.configure({
    placeholder: ({ node }) => {
      if (node.type.name === 'paragraph') {
        return placeholder || t('common:startTyping');
      }
      return placeholder || t('common:startTyping');
    },
  }),
  Strike,
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Text,
  TextStyle,
  ThreeColumnLayout,
  FourColumnLayout,
  TwoColumnLayout,
  Underline,
];

export function useTipTapEditor({ placeholder = '' }) {
  const { t } = useTranslation(['common']);
  return useEditor({
    extensions: getExtensions({
      placeholder,
      t,
    }),
  });
}
