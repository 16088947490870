import {
  Box,
  Card,
  Divider,
  Link,
  Stack,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { BubbleMenu, type Editor } from '@tiptap/react';
import {
  IconButtonWithTooltip,
  getMetaCharacter,
  useDisclosure,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  BulletListIcon,
  LinkIcon,
  NumberListIcon,
  RoundRedoIcon,
  RoundUndoIcon,
  ToggleButtonTableIcon,
} from '@tyro/icons';
import AddColumnsMenu from './add-columns-menu';
import TextAlignmentToggle from './paragraph-formatting/text-alignment-toggle';
import FontPicker from './text-formatting/font-picker';
import FontStyling from './text-formatting/font-style';
import HeadingPicker from './text-formatting/paragraph-formatter';
import { ToggleButton } from './toolbar-buttons/toggle-button';

type BaseMenuTools =
  | 'undoRedo'
  | 'fontPicker'
  | 'fontStyling'
  | 'headings'
  | 'alignment'
  | 'listButtons'
  | 'tables'
  | 'columns'
  | 'strikethrough';

interface BaseMenuToolBarProps {
  editor: Editor | null;
  contextValue?: string;
  omittedTools?: BaseMenuTools[];
  variableSelector?: React.ReactNode;
  editLinkModal?: (props: {
    isOpen: boolean;
    onClose: () => void;
  }) => React.ReactNode;
}

export const BaseMenuToolBar = ({
  editor,
  contextValue,
  omittedTools = [],
  variableSelector,
  editLinkModal,
}: BaseMenuToolBarProps) => {
  const { t } = useTranslation(['templates', 'common']);
  const metaCharacter = getMetaCharacter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentFocusLink = (editor?.getAttributes('link').href ?? '') as string;

  const isToolVisible = (tool: BaseMenuTools) => !omittedTools.includes(tool);

  const dividerStyles = {
    height: '30px',
    alignSelf: 'center',
    backgroundColor: 'lightgray',
  };

  if (!editor) return null;

  return (
    <Card
      component={ToggleButtonGroup}
      aria-label={t('common:menuToolbarItems')}
      sx={{
        display: 'flex',
        position: 'sticky',
        top: '100px',
        zIndex: '2',
        overflow: 'visible',
        backgroundColor: 'slate.50',
        minHeight: '67px',
        paddingX: 0.5,
        '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
          borderLeft: 'none',
        },
        width: 'auto',
        '& hr:last-of-type': {
          display: 'none',
        },
      }}
    >
      {isToolVisible('undoRedo') && (
        <>
          {/* Undo/Redo Buttons */}
          <ToggleButtonGroup
            aria-label={t('common:ariaLabelUndoRedo')}
            size="small"
            exclusive
            sx={{
              '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                borderLeft: 'none',
              },
              border: 'none',
              alignItems: 'center',
              backgroundColor: 'slate.50',
            }}
          >
            <ToggleButton
              title={t('common:tooltipTitles.undo', {
                shortcut: `${metaCharacter} + Z`,
              })}
              onClick={() => editor?.chain().undo().run()}
              value="undo"
            >
              <RoundUndoIcon />
            </ToggleButton>
            <ToggleButton
              title={t('common:tooltipTitles.redo', {
                shortcut: `${metaCharacter} + Y`,
              })}
              onClick={() => editor?.chain().redo().run()}
              value="redo"
            >
              <RoundRedoIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {contextValue && (
        <>
          {variableSelector}
          <Divider
            flexItem
            orientation="vertical"
            sx={{
              height: '30px',
              alignSelf: 'center',
              backgroundColor: 'lightgray',
            }}
          />
        </>
      )}

      {isToolVisible('fontPicker') && (
        <>
          {/* Font Picker */}
          <FontPicker editor={editor} />
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {isToolVisible('headings') && (
        <>
          {/* Heading Picker */}
          <HeadingPicker editor={editor} />
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {isToolVisible('fontStyling') && (
        <>
          <FontStyling editor={editor} />
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {isToolVisible('alignment') && (
        <>
          {/* Text Alignment */}
          <TextAlignmentToggle editor={editor} />
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {isToolVisible('listButtons') && (
        <>
          {/* Bullet/Ordered List Buttons */}
          <ToggleButtonGroup
            aria-label={t('common:ariaLabelListsAndLinks')}
            size="small"
            exclusive
            sx={{
              '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                borderLeft: 'none',
              },
              border: 'none',
              alignItems: 'center',
              backgroundColor: 'slate.50',
            }}
          >
            {/* BULLET LIST */}
            <ToggleButton
              title={t('common:tooltipTitles.bulletedList', {
                shortcut: `${metaCharacter} + 8`,
              })}
              onClick={() => editor?.chain().toggleBulletList().run()}
              selected={editor?.isActive('bulletList')}
              value="bulletList"
            >
              <BulletListIcon />
            </ToggleButton>
            <Divider flexItem orientation="vertical" sx={dividerStyles} />

            {/* NUMBERED LIST */}
            <ToggleButton
              title={t('common:tooltipTitles.numberedList', {
                shortcut: `${metaCharacter} + ⇧ + 7`,
              })}
              onClick={() => editor?.chain().toggleOrderedList().run()}
              selected={editor?.isActive('orderedList')}
              value="orderedList"
            >
              <NumberListIcon />
            </ToggleButton>
            <Divider flexItem orientation="vertical" sx={dividerStyles} />
            {/* Link  */}
            <IconButtonWithTooltip
              title={t('common:insertLink')}
              placement="top"
              onClick={onOpen}
            >
              <LinkIcon />
            </IconButtonWithTooltip>
            {editor && (
              <Box
                component={BubbleMenu}
                className="templates-link-popover"
                editor={editor}
                tippyOptions={{ placement: 'bottom', maxWidth: 1000 }}
                updateDelay={0}
                pluginKey="templates-link-popover"
                shouldShow={(props) => props.editor.isActive('link')}
                sx={{
                  backgroundColor: 'indigo.100',
                  borderRadius: 1,
                  py: 0.5,
                  fontSize: '0.75rem',
                  textWrap: 'nowrap',
                  maxWidth: 1000,
                  '& a, & button': {
                    color: 'indigo.600',
                  },
                }}
              >
                <Stack
                  mx={1}
                  direction="row"
                  display="flex"
                  alignItems="center"
                  spacing={0.5}
                >
                  <Stack direction="row" display="inline-flex" spacing={0.25}>
                    <span>{t('common:goToLink')}</span>
                    <Link
                      href={currentFocusLink}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      sx={{
                        maxWidth: 200,
                        textOverflow: 'ellipsis',
                        display: 'inline-block',
                        overflow: 'hidden',
                      }}
                    >
                      {currentFocusLink}
                    </Link>
                  </Stack>
                  <span>|</span>
                  <Box>
                    <Link component="button" onClick={onOpen}>
                      {t('common:actions.change')}
                    </Link>
                  </Box>
                  <span>|</span>
                  <Box>
                    <Link
                      component="button"
                      onClick={() =>
                        editor.chain().extendMarkRange('link').unsetLink().run()
                      }
                    >
                      {t('common:actions.remove')}
                    </Link>
                  </Box>
                </Stack>
              </Box>
            )}
            {editLinkModal?.({ isOpen, onClose })}
          </ToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {isToolVisible('strikethrough') && (
        <>
          <ToggleButtonGroup
            aria-label={t('common:ariaLabelTableAndColumns')}
            size="small"
            exclusive
            sx={{
              '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                borderLeft: 'none',
              },
              border: 'none',
              alignItems: 'center',
              backgroundColor: 'slate.50',
            }}
          >
            <ToggleButton
              title={t('common:tooltipTitles.horizontalRule')}
              onClick={() => editor.chain().setHorizontalRule().run()}
              value="unLink"
            >
              <Typography fontSize="14px" color="#1e293b">
                {t('templates:horizontalRule')}
              </Typography>
            </ToggleButton>

            <Divider flexItem orientation="vertical" sx={dividerStyles} />

            <ToggleButton
              title={t('common:tooltipTitles.strikeThrough', {
                shortcut: `${metaCharacter} + Shift + X`,
              })}
              onClick={() => editor.chain().toggleStrike().run()}
              value="strikeThrough"
            >
              <Typography fontSize="14px" color="#1e293b">
                {t('common:tooltipTitles.strikeThroughIconText')}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {isToolVisible('tables') && (
        <>
          {/* Table Button */}
          <ToggleButton
            title={t('common:tooltipTitles.table')}
            onClick={() => editor.commands.insertTable({ rows: 3, cols: 3 })}
            value="table"
          >
            <ToggleButtonTableIcon />
          </ToggleButton>
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {isToolVisible('columns') && (
        <>
          {/* Columns Menu */}
          <AddColumnsMenu editor={editor} />
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}
    </Card>
  );
};
