import { Chip as MuiChip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NodeViewWrapper } from '@tiptap/react';
import type { CSSProperties, DragEvent, FC } from 'react';
import React from 'react';

export interface StyledVariableChipProps {
  node: {
    attrs: {
      label: string;
      value?: string;
      style?: CSSProperties;
    };
  };
  getPos: () => number;
  deleteNode: (pos: number) => void;
}

// Styled NodeViewWrapper for inline display
const StyledNodeViewWrapper = styled(NodeViewWrapper)({
  display: 'inline',
  '& .MuiChip-label': {
    pointerEvents: 'none',
  },
  '& > span': {
    display: 'inline',
  },
});

export function VariableChipComponent({
  node,
  deleteNode,
  getPos,
}: StyledVariableChipProps) {
  const handleDelete = () => {
    const pos = getPos();
    deleteNode(pos);
  };

  const handleDragStart = (
    event: DragEvent<HTMLDivElement>,
    label: string,
    value: string,
  ) => {
    // Create a new element for the drag image
    const dragImage = document.createElement('span');
    dragImage.innerText = label;
    // Apply styles to match Chip component or as desired
    dragImage.style.position = 'absolute';
    dragImage.style.top = '-999px';
    dragImage.style.backgroundColor = 'transparent';
    dragImage.style.fontSize = '13px';
    dragImage.style.color = node.attrs.style?.color || '#2e7d32';
    dragImage.style.backgroundColor =
      node.attrs.style?.backgroundColor || '#c8e6c9';

    // Append to the body and use it as the drag image
    document.body.appendChild(dragImage);
    event.dataTransfer.setDragImage(dragImage, 0, 0);

    // Remove the dragImage after the drag starts
    setTimeout(() => document.body.removeChild(dragImage), 0);

    // Set the data to be transferred
    event.dataTransfer.setData('application/x-custom-node', label);
    event.dataTransfer.setData('application/x-custom-node-value', value);

    event.dataTransfer.effectAllowed = 'copy';
  };

  const chipStyles = {
    display: 'inline-flex',
    backgroundColor: node.attrs.style?.backgroundColor || '#FFEDD5',
    color: node.attrs.style?.color || '#EA580C',
    cursor: 'pointer',
    height: '20px',
    fontSize: '13px',
    ...node.attrs.style,
  };

  const convertedLabel = node.attrs.label
    // Remove the backend-specific wrapper and 'v.' prefix & extract correct label value & removes periods with space
    .replace(/\${\(v\.([^)]+)\)!}/, '$1')
    .replace(/\./g, ' ')
    .replace(/(^\w)/, (firstLetter: string) => firstLetter.toUpperCase());
  const { value } = node.attrs;

  return (
    <StyledNodeViewWrapper>
      <MuiChip
        data-type="custom-node"
        onDelete={handleDelete}
        label={convertedLabel}
        size="small"
        sx={chipStyles}
        draggable
        onDragStart={(event) => {
          event.stopPropagation();
          handleDragStart(event, convertedLabel, value ?? '');
        }}
        onDragEnd={() => handleDelete()}
      />
    </StyledNodeViewWrapper>
  );
}
