import type {
  CellRange,
  Column,
  IStatusPanelParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import type { AgGridReact } from '@ag-grid-community/react';
import { useTranslation } from '@tyro/i18n';
import { Fragment, useEffect, useState } from 'react';

type AggregationType = 'sum' | 'avg' | 'count' | 'min' | 'max';

interface ColumnConfig {
  id: string;
  aggregationTypes: AggregationType[];
  labelOverrides?: Partial<
    Record<AggregationType, { selected: string; total: string }>
  >;
  onSelectOnly?: boolean;
}

interface TyroAggStatusPanelProps extends IStatusPanelParams {
  api: AgGridReact['api'];
  columns: ColumnConfig[];
}

interface AggregationResult {
  value: number;
  count: number;
  selectedValue: number;
  selectedCount: number;
}

const aggregationFunctions: Record<
  string,
  (
    values: number[],
  ) => Omit<AggregationResult, 'selectedValue' | 'selectedCount'>
> = {
  sum: (values) => ({
    value: values.reduce((a, b) => a + b, 0),
    count: values.length,
  }),
  avg: (values) => ({
    value: values.length
      ? values.reduce((a, b) => a + b, 0) / values.length
      : 0,
    count: values.length,
  }),
  count: (values) => ({ value: values.length, count: values.length }),
  min: (values) => ({
    value: values.length ? Math.min(...values) : 0,
    count: values.length,
  }),
  max: (values) => ({
    value: values.length ? Math.max(...values) : 0,
    count: values.length,
  }),
};

export function TyroAggStatusPanel(props: TyroAggStatusPanelProps) {
  const { t } = useTranslation(['common']);
  const [aggregations, setAggregations] = useState<
    Record<string, Record<AggregationType, AggregationResult>>
  >({});
  const [selectedColumns, setSelectedColumns] = useState<Set<string>>(
    new Set(),
  );

  const getColumnValue = (column: Column, data: unknown) => {
    const valueGetter = column.getColDef().valueGetter;
    if (valueGetter && typeof valueGetter === 'function') {
      return valueGetter({
        data: data,
        node: null,
        column: column,
        colDef: column.getColDef(),
        api: props.api,
        getValue: () => null,
        context: null,
      });
    }
    // @ts-expect-error
    return data[column.getColId()];
  };

  const calculateAggregations = (cellRanges: CellRange[] | null = null) => {
    const newAggregations: Record<
      string,
      Record<AggregationType, AggregationResult>
    > = {};
    const newSelectedColumns = new Set<string>();

    for (const columnConfig of props.columns) {
      const values: number[] = [];
      const selectedValues: number[] = [];

      props.api.forEachNode((node) => {
        const value = getColumnValue(
          props.api.getColumn(columnConfig.id)!,
          node.data,
        );
        if (typeof value === 'number' && !Number.isNaN(value)) {
          values.push(Math.round(value * 100));
        }
      });

      if (cellRanges && cellRanges.length > 0) {
        for (const range of cellRanges) {
          if (range.columns.some((col) => col.getColId() === columnConfig.id)) {
            const startRow = Math.min(
              range.startRow?.rowIndex ?? 0,
              range.endRow?.rowIndex ?? 0,
            );
            const endRow = Math.max(
              range.startRow?.rowIndex ?? 0,
              range.endRow?.rowIndex ?? 0,
            );
            for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
              const rowNode = props.api.getDisplayedRowAtIndex(rowIndex);
              if (rowNode) {
                const value = getColumnValue(
                  props.api.getColumn(columnConfig.id)!,
                  rowNode.data,
                );
                if (typeof value === 'number' && !Number.isNaN(value)) {
                  if (!newSelectedColumns.has(columnConfig.id)) {
                    newSelectedColumns.add(columnConfig.id);
                  }
                  selectedValues.push(Math.round(value * 100));
                }
              }
            }
          }
        }
      }

      newAggregations[columnConfig.id] = {} as Record<
        AggregationType,
        AggregationResult
      >;
      for (const aggType of columnConfig.aggregationTypes) {
        const totalResult = aggregationFunctions[aggType](values);
        const selectedResult = aggregationFunctions[aggType](selectedValues);
        newAggregations[columnConfig.id][aggType] = {
          value: totalResult.value / 100,
          count: totalResult.count,
          selectedValue: selectedResult.value / 100,
          selectedCount: selectedResult.count,
        };
      }
    }

    return { newAggregations, newSelectedColumns };
  };

  useEffect(() => {
    if (props.api) {
      const updateAggregations = () => {
        const { newAggregations, newSelectedColumns } = calculateAggregations(
          props.api.getCellRanges(),
        );
        setAggregations(newAggregations);
        setSelectedColumns(newSelectedColumns);
      };

      updateAggregations();

      props.api.addEventListener('modelUpdated', updateAggregations);
      props.api.addEventListener('rangeSelectionChanged', updateAggregations);

      return () => {
        props.api.removeEventListener('modelUpdated', updateAggregations);
        props.api.removeEventListener(
          'rangeSelectionChanged',
          updateAggregations,
        );
      };
    }
  }, [props.api, props.columns]);

  const shouldRenderColumn = (columnConfig: ColumnConfig) => {
    const anyColumnSelected = selectedColumns.size > 0;
    if (!anyColumnSelected) {
      return !columnConfig.onSelectOnly;
    }
    if (selectedColumns.has(columnConfig.id)) {
      return true;
    }
    return false;
  };

  const renderAggregation = (
    columnConfig: ColumnConfig,
    aggType: AggregationType,
    result: AggregationResult | undefined,
    isSelected: boolean,
  ) => {
    if (!result || (isSelected ? result.selectedCount : result.count) === 0)
      return null;

    const valueFormatter = props.api
      .getColumn(columnConfig.id)
      ?.getColDef()?.valueFormatter;
    const value = isSelected ? result.selectedValue : result.value;
    const formattedValue =
      valueFormatter && typeof valueFormatter !== 'string'
        ? valueFormatter({
            data: { [columnConfig.id]: value },
            value,
          } as ValueFormatterParams<unknown, number>)
        : value;

    let label =
      columnConfig.labelOverrides?.[aggType]?.[
        isSelected ? 'selected' : 'total'
      ];

    if (!label) {
      const columnName = props.api
        .getColumnDef(columnConfig.id)
        ?.headerName?.toLowerCase();
      label = t(
        `common:tyroAggStatus.${isSelected ? 'selected' : 'all'}.${aggType}`,
        { name: columnName },
      );
    }
    return (
      <div
        key={`${columnConfig.id}-${aggType}-${isSelected}`}
        className="ag-status-name-value"
      >
        <span data-ref="eLabel">{label}</span>:{' '}
        <span data-ref="eValue">{formattedValue}</span>
      </div>
    );
  };

  return (
    <div className="ag-status-panel ag-status-panel-aggregations">
      {props.columns.map((columnConfig) => {
        if (!shouldRenderColumn(columnConfig)) return null;
        const isSelected = selectedColumns.has(columnConfig.id);
        return (
          <Fragment key={columnConfig.id}>
            {columnConfig.aggregationTypes.map((aggType) => (
              <Fragment key={`${columnConfig.id}-${aggType}`}>
                {renderAggregation(
                  columnConfig,
                  aggType,
                  aggregations[columnConfig.id]?.[aggType],
                  isSelected,
                )}
              </Fragment>
            ))}
          </Fragment>
        );
      })}
    </div>
  );
}
